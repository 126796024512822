import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Typography from "/src/components/ui/base/typography"
import Button from "/src/components/ui/base/Button"
import Header from "../components/ui/base/layout/header"
import { navigate } from "../../.cache/commonjs/gatsby-browser-entry"
import Section from "../components/ui/base/Section"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import CollectionCard from "../components/ui/base/CollectionCards"
import { graphql, useStaticQuery } from "gatsby"
import TourCard from "../components/ui/base/TourCard"
import GallerySection from "../components/ui/extended/GallerySection"
import LastMinuteSection from "../components/ui/extended/LastMinuteSection"
import IconWithTextSection from "../components/ui/base/IconWithTextSection"
import {
  COLLECTIONS_URL,
  COURSES_URL,
  PHOTO_TOURS_URL,
} from "../util/constants"
import CourseCard from "../components/ui/base/CourseCard"
import { GatsbyImage } from "gatsby-plugin-image"
import { tourExistsIn } from "../util/tour"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"

const IndexPage = () => {
  const { t, i18n: l } = useTranslation()
  const data = useStaticQuery(query)

  const homePage = data.strapiHomePage
  const collectionSection = homePage.CollectionSection
  const toursSection = homePage.Tours
  const whatsColumns = homePage.IconWithTextSection.iconColumn
  const whyColumns = homePage.IconWhySection.iconColumn
  const SEO = homePage.SEO

  const tours = data.allStrapiTour.edges.filter(t => {
    if (tourExistsIn(t.node, toursSection.tours)) {
      return true
    } else {
      return false
    }
  })

  const courses = data?.allStrapiCourse.edges
  const imageAcademy = data?.allFile.edges[0].node

  function goToTours() {
    navigate(`/${PHOTO_TOURS_URL[l.language]}/`)
  }

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        title={SEO.metaTitle}
        description={SEO.metaDescription}
        language={l.language}
      />

      <Header size={"big"} videoURL={"weshoot-viaggi-fotografici"} theme="dark">
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  {homePage.title}
                </Typography>
                <Container
                  fluid="xl"
                  className={classnames([styles.buttonContainer])}
                >
                  <Button
                    variant={"header"}
                    className={classnames([styles.cta_book])}
                    onClick={() => goToTours()}
                  >
                    {t("ui.pages.home.discoverTrips")}{" "}
                  </Button>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section
        className={classnames([styles.description])}
        variant={"white"}
        linkAll={`/${PHOTO_TOURS_URL[l.language]}/${
          COLLECTIONS_URL[l.language]
        }`}
        title={collectionSection.title}
        subtitle={collectionSection.subtitle}
      >
        {collectionSection && (
          <div className={classnames([styles.collectionContainer])}>
            {collectionSection.collections.map((collection, i) => {
              return (
                <CollectionCard
                  collection={collection}
                  key={`collection-${i}`}
                />
              )
            })}
          </div>
        )}
      </Section>

      <Section
        variant={"grey"}
        linkAll={`/${PHOTO_TOURS_URL[l.language]}/`}
        title={toursSection.title}
        subtitle={toursSection.subtitle}
      >
        {tours && tours.length > 0 && (
          <Row>
            {tours.map((tour, i) => {
              if (i < 6) {
                return (
                  <Col lg={4} md={6} className="zoomIn mb-2" key={i}>
                    <TourCard tour={tour.node} />
                  </Col>
                )
              }
              return <></>
            })}
          </Row>
        )}
      </Section>

      <Section
        variant={"white"}
        title={t("ui.pages.home.whySection.title")}
        subtitle={t("ui.pages.home.whySection.subtitle")}
      >
        <IconWithTextSection columns={whyColumns} />
        {data?.allStrapiPicture.edges && (
          <GallerySection pictures={data?.allStrapiPicture.edges} />
        )}
      </Section>

      <LastMinuteSection />

      <Section
        variant={"grey"}
        title={t("ui.pages.home.whatsWeshoot.title")}
        subtitle={t("ui.pages.home.whatsWeshoot.subtitle")}
      >
        <IconWithTextSection columns={whatsColumns} />
      </Section>

      <Section
        variant={"dark"}
        linkAll={`/${COURSES_URL[l.language]}/`}
        title="Prima di partire, impara con i corsi di fotografia"
        subtitle="Impara da vincitori di contest internazionali, sviluppa il tuo stile fotografico."
      >
        {courses && courses.length > 0 && (
          <Row>
            {courses.map((course, i) => (
              <Col lg={4} key={i} className="mb-1">
                <CourseCard course={course.node} />
              </Col>
            ))}
          </Row>
        )}
      </Section>

      <Section
        variant={"white"}
        title="La piú completa accademia fotografica online di fotografia paesaggistica"
        subtitle="Giá 2880+ studenti hanno studiato sull'accademia fotografica di WeShoot."
      >
        <Row className="pt-8 pb-8">
          <Col md={6}>
            <GatsbyImage
              image={imageAcademy?.childImageSharp.gatsbyImageData}
              alt={"Accademia fotografica Online"}
            />
          </Col>
          <Col md={6}>
            <Typography variant="heading3">
              Accedi all'
              <strong style={{ color: "#FC4D33" }}>
                accademia fotografica
              </strong>
            </Typography>
            <Typography variant="heading4">
              Migliora le tue foto seguendo i consigli di professionisti
            </Typography>
            <br />
            <ul className="list_order">
              <li>
                <span>1</span>Segui i{" "}
                <strong>corsi online di fotografia</strong>
              </li>
              <li>
                <span>2</span>Condividi con la community le tue foto
              </li>
              <li>
                <span>3</span>Diventa un master
              </li>
            </ul>
            <Button variant={"link"} to="https://accademia.weshoot.it/">
              Inizia Gratis
            </Button>
          </Col>
        </Row>
      </Section>
    </>
  )
}
export const query = graphql`
  {
    allStrapiCourse(limit: 6) {
      edges {
        node {
          title
          excerpt
          presentation
          totalLessons
          url
          slug
          teacher {
            profilePicture {
              localFile {
                ...ImageFragment
              }
            }
            firstName
          }
          price
          cover {
            localFile {
              ...ImageFragment
            }
          }
        }
      }
    }
    allFile(filter: { name: { eq: "Corso_Fotografia_online_weshoot" } }) {
      edges {
        node {
          ...ImageFragment
        }
      }
    }
    allStrapiTour {
      ...StrapiTourHomeFragment
    }
    strapiHomePage {
      title
      SEO {
        metaDescription
        metaTitle
        shareImage {
          height
        }
      }
      CollectionSection {
        title
        subtitle
        collections {
          image {
            localFile {
              ...ImageFragment
            }
          }
          name
          locale
          slug
          description
          excerpt
        }
      }
      Tours {
        title
        subtitle
        tours {
          id
          tour_id
        }
      }
      IconWhySection {
        iconColumn {
          description
          title
          icon {
            localFile {
              ...ImageFragment
            }
          }
        }
      }
      IconWithTextSection {
        iconColumn {
          description
          title
          icon {
            localFile {
              ...ImageFragment
            }
          }
        }
      }
      whatsWeshoot {
        subtitle
        title
      }
    }

    allStrapiPicture(
      filter: { type: { eq: "group" } }
      limit: 12
      sort: { order: DESC, fields: image___created_at }
    ) {
      edges {
        node {
          image {
            localFile {
              ...ImageFragment
            }
            alternativeText
          }
          title
        }
      }
    }
    allStrapiCollection(limit: 4) {
      nodes {
        name
        slug
        excerpt
        image {
          localFile {
            ...ImageFragment
          }
        }
      }
    }
  }
  fragment StrapiTourHomeFragment on StrapiTourConnection {
    edges {
      node {
        id
        tour_id
        image {
          url
          localFile {
            ...ImageFragment
          }
        }
        slug
        states {
          slug
        }
        places {
          slug
        }
        sessions {
          end
          id
          sessionId
          start
          status
          maxPax
          balance
          price
          deposit
          currency
          users {
            id
            firstName
            instagram
            profilePicture {
              localFile {
                ...ImageFragment
              }
            }
          }
        }
        excerpt
        experienceLevel
        title
      }
    }
  }
`
export default IndexPage
