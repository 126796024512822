import classnames from "classnames"
import React from "react"
import * as styles from "./index.module.scss"
import capitalize from "/src/util/capitalize"
import { GatsbyImage } from "gatsby-plugin-image"

const Header = ({ children, plain, theme, videoURL, img, size }) => {
  if (size === undefined) {
    size = "big"
  }

  if (plain) {
    return <div className={classnames([styles.containerPlain])}>{children}</div>
  } else {
    if (videoURL) {
      return (
        <div
          className={classnames([
            styles.container,
            {
              [styles[`theme${capitalize(theme)}`]]: theme,
              [styles[`size${capitalize(size)}`]]: size,
            },
          ])}
        >
          <div className={classnames([styles.overlay])}>
            <video
              className={classnames([styles.video_play])}
              loop
              muted
              autoPlay
              playsInline
            >
              <source
                src={`/media/videos/it/viaggi-fotografici-video/${videoURL}.mp4`}
                crossOrigin="anonymous"
                type="video/mp4"
              />
              <source
                src={`/media/videos/it/viaggi-fotografici-video/${videoURL}.webm`}
                crossOrigin="anonymous"
                type="video/webm"
              />
            </video>
          </div>
          <div className={classnames([styles.gradient, styles.overlay])} />
          <div className={classnames([styles.gradient, styles.overlay])} />
          {children}
        </div>
      )
    } else {
      return (
        <div
          className={classnames([
            styles.container,
            {
              [styles[`theme${capitalize(theme)}`]]: theme,
              [styles[`size${capitalize(size)}`]]: size,
            },
          ])}
        >
          <div className={classnames([styles.overlay])}>
            {img && (
              <GatsbyImage
                alt={"Viaggi Fotografici"}
                loading="eager"
                className={classnames([styles.image_background])}
                image={img}
              />
            )}
          </div>
          <div className={classnames([styles.gradient, styles.overlay])} />
          <div className={classnames([styles.gradient, styles.overlay])} />
          {children}
        </div>
      )
    }
  }
}
export default Header
