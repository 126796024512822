import classnames from "classnames"
import React from "react"
import Link from "/src/components/ui/base/Link"
import * as styles from "./index.module.scss"
import Typography from "/src/components/ui/base/typography"
import { useTranslation } from "react-i18next"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { COLLECTIONS_URL, PHOTO_TOURS_URL } from "../../../../util/constants"

const CollectionCard = ({ collection }) => {
  const { i18n:l } = useTranslation()

  const collectionLink = `/${PHOTO_TOURS_URL[l.language]}/${COLLECTIONS_URL[l.language]}/${collection.slug}`

  return (<Link className={classnames([styles.container])} to={collectionLink}>
        <GatsbyImage  className={classnames([styles.gatsbyImage])} alt={collection.name} image={collection?.image?.localFile?.childImageSharp.gatsbyImageData}/>
        <div className={classnames([styles.wrapper])}>
          <Typography
            variant="heading2">
            {collection.name}
          </Typography>
          <Typography
            variant="heading3">
            {collection.excerpt}
          </Typography>
        </div>
      </Link>
  )
}
CollectionCard.propTypes = {
  collection: PropTypes.any,
}

CollectionCard.defaultProps = {
  collection: null,
}

export default CollectionCard
