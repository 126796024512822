import classnames from "classnames"
import React from "react"
import PropTypes from "/src/util/PropTypes"
import * as styles from "./index.module.scss"
import Typography from "/src/components/ui/base/typography"
import Button from "../Button"
import { GatsbyImage } from "gatsby-plugin-image"
import { CourseJsonLd } from "gatsby-plugin-next-seo"
import { COURSES_URL } from "../../../../util/constants"
import { useTranslation } from "react-i18next"
import Link from "../Link"

const CourseCard = ({ course }) => {
  const { t, i18n: l } = useTranslation()

  const courseLink = `/${COURSES_URL[l.language]}/${course.slug}`

  return (
    <Link to={courseLink}>
      <CourseJsonLd
        courseName={course.title}
        providerName="WeShoot"
        providerUrl={course.url}
        description={course.excerpt}
      />
      <div className={classnames([styles.course_container])}>
        <div className={classnames([styles.img_container])}>
          <GatsbyImage
            image={course.cover.localFile?.childImageSharp.gatsbyImageData}
            alt={`${course.title}`}
            className="img-fluid"
          />
        </div>
        <div className={classnames([styles.card_body])}>
          <Typography variant="heading3">{course.title}</Typography>
          <Typography variant="heading6">{course.excerpt}</Typography>

          <div className={classnames([styles.coach])}>
            <div className={classnames([styles.profile])}>
              <GatsbyImage
                image={
                  course.teacher.profilePicture.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={course.teacher.firstName}
              />
            </div>

            <div className={classnames([styles.coaches_names])}>
              <span>{course.teacher.firstName}</span>
            </div>
          </div>

          <div className={"d-flex align-items-center justify-content-between"}>
            <div className={classnames([styles.card_price])}>
              <span className={classnames([styles.card_lessons])}>
                {course.totalLessons} lezioni a
              </span>
              <span className={classnames([styles.card_price_value])}>
                €{course.price}
              </span>
            </div>

            <Button
              variant={"link"}
              to={`/${COURSES_URL[l.language]}/${course.slug}`}
            >
              Vedi Corso
            </Button>
          </div>
        </div>
      </div>
    </Link>
  )
}

CourseCard.propTypes = {
  course: PropTypes.any,
}

CourseCard.defaultProps = {
  course: null,
}

export default CourseCard
