// extracted by mini-css-extract-plugin
export var containerPlain = "index-module--containerPlain--oQcMx";
export var container = "index-module--container--YN0FZ";
export var overlay = "index-module--overlay--fmf0K";
export var video_play = "index-module--video_play--qi9KG";
export var fadeIn = "index-module--fadeIn--nmriP";
export var image_background = "index-module--image_background--WGByn";
export var gradient = "index-module--gradient--dGooH";
export var themeLight = "index-module--theme-light--cicvk";
export var themeDark = "index-module--theme-dark--j6wBh";
export var sizeCompact = "index-module--size-compact--6vLuh";
export var sizeBig = "index-module--size-big--O0DE1";
export var centered = "index-module--centered--SdFSM";